#upload-avatar {

  .add-logo {
    border: #70c2ac solid 1px;
    font-size: 16px;
    color: #70c2ac;
    padding: 2px 10px 2px 10px;

    &:hover {
      border: #40a9ff solid 1px;
      color: #40a9ff;
    }
  }

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .avatar {
    margin: 20px;
  }
}


@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;