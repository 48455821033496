#welcome-page {
  padding-top: 2rem;

  .greeting-container {
    color: #70c2ac;

    p {
      font-size: 1.1rem;
      margin-bottom: 4rem;

      .username {
        font-size: 1.4rem;
      }
    }
  }

  .illustration-container img {
    display: inline-block;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .welcome-message-container {
    margin-bottom: 5rem;

    .title {
      font-weight: bold;
      color: #000;
      font-size: 1.2rem;
      margin-bottom: 30px;
    }

    .message {
      font-size: 1.1rem;
    }
  }

  .action-card-container {
  }

  @media screen and (max-width: 761px) {
    .illustration-container {
      display: none;
    }

    .welcome-message-container {
      margin-bottom: 20px;
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;