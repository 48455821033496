#success-page {
  text-align: center;
  padding: 60px;
  .icon {
    width: 73px;
  }

  .title {
    margin-top: 20px;
  }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;