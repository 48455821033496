.objective-differentials-table {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: transparent;
  }

  .ant-table-thead > tr {
    > th {
      padding: 0px;
      background: unset;
      border-bottom: unset;
      padding: 0 20px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      span {
        text-align: center;
        padding: 10px;
        display: block;

        &.differential {
          background-color: #5ac4f6;
        }
        &.evidence {
          background-color: #64c466;
        }
       &.findings {
          background-color: #ea4d3d;
        }
        &.examined {
          background-color: #64c4be;
        }
        &.comments {
          background-color: rgba(196, 146, 100, 0.27);
        }
      }
    }
  }

  .ant-form-item-control-input-content {
    text-align: center;
  }

  .ant-form-item  {
    margin-bottom: 0;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;