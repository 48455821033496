.speech-to-text-input-wrapper {
  position: relative;

  .input-wrapper {
    display: flex;
    flex-direction: column;

    .recording-label {
      text-align: right;
    }
  }

  .ant-form-item-extra {
    text-align: right;
    font-size: 12px;
  }

  .record-button {
    position: absolute;
    bottom: 34px;
    right: 13px;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;