.card-title{
  position: absolute;
  font-size: 1rem;
  background-color: #ffc107;
  color: #ffffff;
  border-radius: 3rem;
  align-content: center;
  padding: 5px 30px 5px 30px;
  top: -20px;
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;