#dashboard-layout {
  .site-base-layout {
    min-height: 100vh;
    margin-left: 264px;
  }

  .page-header {
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.07);

    .ant-page-header-heading-title {
      text-transform: capitalize;
    }

    .profile-name-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 990px) {
    .site-base-layout {
      margin-left: 0px;
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;