#pet-profile {
  .profile-details-container {
    background-color: #fff;

    border-radius: 15px;
    position: relative;
    border: 1px solid #e9e9e9;
    padding: 15px;
    
    .pet-info-container {
      display: flex;
      gap: 15px;
      align-items: center;
      .pet-img-container  {
        border: 2px solid rgba(29, 164, 180, 1);
        border-radius: 8px;
        width: 166px;
        height: 207px;
        overflow: hidden;
      }

      .details-list {
        flex: 1;
      }
    }

    .problem-list-container {
      padding: 31px 0px;
  

      .problem-list-item {
        padding: 10px;
        background-color: #fcfffe;
        border-radius: 19px;
        margin-right: 10px;
        margin-top: 10px;
        color: #1da4b4;
        font-size: 16px;
        border: 1px solid #f3f3f3;
      }
    }


    .ant-card {
      background-color: #fcfcfc;
      height: 100%;
      border: 0;

      &-head {
        min-height: 36px;
        padding: 0 12px;
        font-size: 17px;
        background-color: #f8f8f8;
        color: var(--antd-wave-shadow-color);
        border: 0;
      }
    }

    .data-item {
      display: flex;
      // flex-direction: column;
      padding: 13px;
      flex: 1;
      background-color: #fff;
      border-radius: 3px;
      margin: 5px 0px;
    }

    .type-text {
      position: absolute;
      display: inline-block;
      background-color: #febb5c;
      top: -14px;
      color: #fff;
      padding: 0px 9px;
      border-radius: 14px;
      left: 8px;
      text-transform: capitalize;
      z-index: 2;
    }
    
    .details-container {
      background-color: #fafafa;
      align-self: center;
      flex: 1 1;
      padding: 8px 20px;
      border-radius: 17px;
      position: relative;
      align-self: stretch;

      .row {
        display: flex;
        margin: 6px 0px;
      }
    }
  }

  .profile-visit-details-container {
    margin-top: 30px;

    .ant-collapse {
      background-color: unset;
    }
    .ant-collapse-borderless {
      > .ant-collapse-item {
        border: 1px solid transparent;
        background: #f8f8f8;
        margin-bottom: 20px;
        border-radius: 17px;
        overflow: hidden;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
      }

      > .ant-collapse-item-active {
        border-color: #70c2ac;
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: rgba(243, 255, 251, 1);
      padding: 19px 16px;
      font-weight: bold;
      display: flex;
      align-items: center;

      .custom-panel-title-container {
        flex: 1;
      }

      .add-visit-panel-button {
        margin-right: 30px;
      }
    }

    .ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding: 26px 20px;
    }

    // .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    //   padding-top: 18px;
    // }

    .ant-table {
      tr.ant-table-expanded-row > td {
        box-shadow: none;
        background-color: #fff;
      }
    }
  }

  .visit-history-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .visit-history-list-item {
      display: flex;
      border-radius: 8px;
      background-color: #fff;
      padding: 13px 30px;
      justify-content: space-between;
      border: 0.7px solid transparent;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
      margin-bottom: 10px;

      &:hover {
        border-color: #70c2ac;
        cursor: pointer;
      }

      .details-container {
        display: flex;
        align-items: center;
        flex: 1;

        .date,
        .time {
          flex: 0 0 150px;
        }

        .date,
        .time,
        .status {
          margin-right: 3rem;
        }

        .status {
          padding: 2px 10px;
          border-radius: 27px;
          border: 1px solid #70c2ac;
          color: #70c2ac;
        }
      }

      .actions-container {
      }
    }
  }

  .text-label,
  .text-value {
    font-size: 14px;
    // font-weight: bold;
  }

  .text-label {
    color: #7d7d7d;
    margin-right: 20px;
    min-width: 61px;
    display: inline-block;
  }

  .text-value {
    color: #000000;
  }
}

.pin-btns {
  display: flex;
  justify-content: center;

  .btn-cancel,
  .btn-lock {
    width: 120px;
    height: 46px;
    margin: 0 15px;
  }
}

.history-expanded-wrapper {
  padding: 5px;
  background: #fff;
}

.history-expanded-wrapper {
  h3 > {
    font-size: 16px;
    font-weight: bold;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;