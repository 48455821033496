.custom-input {
  box-shadow: 0 2px 4px rgba(0,0,0,.1)!important;
  &.ant-input-lg {
    padding: 4px 17px !important;
    height: 55px;
  }


  &.ant-input-affix-wrapper-lg {
    padding: 4px 17px !important;
    height: 55px;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;