.modal-info {
  .ant-modal-body {
    display: flex;
    .info-image {
      width: 22px;
      height: 22px;
    }
    .modal-text {
      margin-left: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Character/Title .85 */

      color: rgba(0, 0, 0, 0.85);
    }
  }
}

#result-treatment-table {
  margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    
  .primary-tag-outline {
    font-weight: 500;
    font-size: 20px;
    // background: #eefff9;
  }
  .treatment-search {
    margin: 10px;
  }

  .expanded-table {
    margin-bottom: 30px;

    th,
    td {
      border: 0px !important;
      text-align: center;
    }

    td:first-child {
      text-align: left;
    }

    th {
      font-weight: 400 !important;
    }

    .range-container {
      display: inline-block;
      background-color: #f8f8f8;
      padding: 5px 9px;
      min-width: 106.63px;
    }
  }

  .remarks-panel {
    margin: 0 15px;
    border: unset;
  }
  .ant-collapse-content,
  .ant-collapse > .ant-collapse-item {
    border: unset;
  }

  .ant-table-tbody {
    .arrow-image {
      height: 12px;
      width: 20px;
      position: relative;
      transform: scale(0.64117647);
      transition: all 0.3s;
      display: inline-flex;
      float: left;
      max-width: unset;
      max-height: unset;
    }

    .logo-image {
      width: 26px;
      height: 23px;
      max-width: unset;
      max-height: unset;
    }

    .edit-image {
      width: 14px;
      height: 14px;
      max-width: unset;
      max-height: unset;
    }
    .delete-image {
      width: 14px;
      height: 14px;
      max-width: unset;
      max-height: unset;
    }

    .action-items {
      display: flex;

      .edit-text {
        height: 18px;
        /* display: block; */
        margin: auto auto auto 6px;
        color: #5ec7d3;
      }
      .delete-text {
        height: 18px;
        /* display: block; */
        margin: auto auto auto 6px;
        color: #c01e4f;
      }
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        background: #fff;
        box-shadow: unset;

        .expanded-table-content-container {
          margin-left: 100px;
          margin-top: 7px;
          display: flex;

          .heading {
            color: #5ec7d3;
          }
        }
      }
    }
  }

  .ant-table-expanded-row {
    background-color: #fff;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;