#auth-page-wrapper {
  display: flex;
  width: 100vw;
  min-height: 100vh;

  .auth-container {
    flex: 1;
    min-height: 100vh;
    background-image: url(../../imgs/auth-bg.png);
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
    position: relative;

    #overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      color: #fff;
      font-size: 22px;

      p {
        max-width: 420px;
      }
    }
  }
  .form-wrapper {
    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;


    .container {
      padding-top: 2rem;
      max-height: 100vh;
      
    }

    .logo-container {
      margin-bottom: 30px;
      text-align: center;

      img {
        width: 89px;
        height: 78px;
      }
    }

    .form-title {
      font-size: 24px;
      &-sub {
        font-size: 16px;
        color: rgba(95, 93, 93, 1);
        margin-bottom: 40px;
      }
    }
  }

  @media screen and (max-width: 761px) {
    .auth-container {
      display: none;
    }

    .container {
      padding: 10%;
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;