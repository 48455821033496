#report {
  margin-left: 100px;
  margin-right: 100px;

  .table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling for small screens */
  
    /* Custom styles for small screens */
    @media (max-width: 768px) {
      /* Adjust font size, cell padding, or any other styles as needed */
      font-size: 14px;
      padding: 8px;
    }
  }
  
  @media screen and (max-width: 761px) {
    margin-left: 20px;
    margin-right: 20px;
    
  }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;