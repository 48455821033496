.signup-custom-layout {
  .form-title-container {
    background-color: #f0f0f0;
    padding: 5px 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 5px;

    .title {
      margin-bottom: 0px;
    }
  }

  .signin-container {
    margin-top: 20px;

    hr {
        display: block;
        margin-bottom: 20px;
        margin-top: 35px;
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;