#soap-success-page {
  text-align: center;
  padding: 40px 0px;
  .image-container {

    img {
      max-width: 400px;
    }
  }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;