.custom-card {
    // box-shadow: 7px 13px 21px rgba(0, 0, 0, 0.07);

    .ant-card {
        &-head {
            border: 0px;
            margin: 0;
        }

        &-extra {
            float: unset;
            margin: 0;
            padding: 21px 0;
            width: 100%;
        }
    }
}


@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;