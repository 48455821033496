@import url('../../styles/summary-common.less');

#treatment-plan-summary-container {
  background: #fcfcfc;

  section {
    width: 100%;
    margin-bottom: 32px;
  }

  #wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 64px 12px;
    padding-bottom: 128px;
    align-items: center;
    max-width: 1080px;
    margin: auto;

    .table-container {
      overflow-x: hidden;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }

    .logo {
      width: 80px;
    }

    .report-card {
      padding: 20px;
      border-radius: 8px;
      background: #fff;
    }

    .clinic-doctor-info {
      width: 100%;
    }

    .section-heading {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      border-radius: 4px;
      background: #f6f6f6;
      width: 100%;
      margin: 32px 0;
    }

    @media only screen and (min-width: 600px) {
    }

    @media only screen and (min-width: 761px) {
    }

    @media only screen and (min-width: 992px) {
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;