#soap-base-layout {

  .nav-button-container {
    margin-bottom: 20px;
  }

  .soap-steps-container {
    background-color: #fff;
    padding: 19px;
    border-radius: 4px;
    border: 1px solid #F4F4F4;
  }

  .step-content-container {
    margin-top: 15px;
    background-color: #fff;
    padding: 16px 14px;
    border-radius: 8px;
  }

  .treatmentHTMLHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #EEFFF9;
    padding: 8px 16px;
    border-radius: 8px;

    .btn-wrapper{
      display: flex;
    }

    h4{
      font-size: 20px;
      line-height: 30px;
      color: #555;
      font-weight: 500;
      margin-bottom: 0;
    }
    // button{
    //   border: 2px solid #1DA4B4;
    //   border-radius: 8px;
    //   background: transparent;
    //   padding: 8px 16px;
    //   height: 46px;
    //   width: 78px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   cursor: pointer;

    //   span{
    //     color: #1DA4B4;
    //   }
    // }
  }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;