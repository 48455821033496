#integrations {
  .app-card {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(185, 185, 185, 1);
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(185, 185, 185, 0.25);

    .app-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .logo-container {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        img {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }

        .ant-typography {
          margin-bottom: 0px;
        }
      }

      .not-enabled-tag {
        color: black;
        text-transform: capitalize;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;