#signin {
  .forgot-password-container {
    display: flex;
    width: 100%;
    margin-bottom: 11px;
    flex-direction: row-reverse;
    margin-top: -12px;

    a {
      color: rgba(95, 93, 93, 1);
    }
  }

  .signup-link-text {
    text-align: center;
    margin-top: 20px;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;