/* --- STYLE ADDED FOR INVOICE SUMMARY PAGES */
.w-full {
  width: 100%;
}
.gap-x-6 {
  column-gap: 36px;
}
.mt-1 {
  margin-top: 16px;
}
.mb-1 {
  margin-bottom: 16px;
}
.mt-2 {
  margin-top: 32px;
}
.mb-2 {
  margin-bottom: 32px;
}
.ln-12 {
  line-height: 12px;
}
.lh-14 {
  line-height: 14px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.light-text {
  color: #606060;
}
.dark-text {
  color: #373737;
}
.normal-text {
  color: #000;
}
