.antech-modal {
  .ant-modal {
    &-body {
      padding: 38px;
    }
  }

  .description {
    margin-bottom: 35px;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;