.action-card-container {
    background-color: #fff;
    padding: 32px 26px;
    border-radius: 10px;
    box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.05);
    max-height: 254px;
    min-height: 254px;
    cursor: pointer;

    border: 2px solid transparent;

    &:hover {
      border-color: #70c2ac;
    }

    .icon-container {
      margin-bottom: 25px;

      > * {
        width: 40px;
      }
    }

    .title {
      font-weight: bold;
    }

    .description {
      margin-bottom: 0px;
    }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;