#data-table {

 min-height: 100vh;

 .ant-card {
   border-radius: 13px;
   border: 1px solid #E9E9E9;
   box-shadow: none;
 }

  .header-elements-container {
    display: flex;
    justify-content: space-between;

    .default-elements {
      display: flex;
    }
  }

  .ant-table {
    &-thead {
      > tr > th {
        background: none;
        font-size: 0.9rem;
        font-weight: 600;
      }
    }

    &-tbody {
        > tr > td {
            padding: 11px 16px;
        }
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;