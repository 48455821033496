.modal-info {
    .ant-modal-body {
        
        display: flex;
        .info-image {
            width: 22px;
            height: 22px;
        }
        .modal-text {
            margin-left: 16px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */
        
        
            /* Character/Title .85 */
        
            color: rgba(0, 0, 0, 0.85);
        }
    }
}
.list-container {
    
    .primary-tag-outline {
        font-weight: 500;
        font-size: 20px;
        background: #EEFFF9;
    }
    .treatment-search {

        margin: 10px;
    }
    .ant-table-tbody {

        .arrow-image {
            height: 12px;
            width: 20px;
            position: relative;
            transform: scale(0.64117647);
            transition: all 0.3s;
            display: inline-flex;
            float: left;
            max-width: unset;
            max-height: unset;
        }

        .logo-image {
            width: 26px;
            height: 23px;
            max-width: unset;
            max-height: unset;
        }

        .edit-image {
            width: 14px;
            height: 14px;
            max-width: unset;
            max-height: unset;
        }
        .delete-image {
            width: 14px;
            height: 14px;
            max-width: unset;
            max-height: unset;
        }

        .action-items {
            display: flex;

            .edit-text {
                height: 18px;
                /* display: block; */
                margin: auto auto auto 6px;
                color: #5EC7D3;
            }
            .delete-text {
                height: 18px;
                /* display: block; */
                margin: auto auto auto 6px;
                color: #C01E4F;
            }
        }

        .ant-table-expanded-row {

            .ant-table-cell {
                background: #F5FFFC;
                box-shadow: unset;

                .expanded-table-content-container {
                    margin-left: 100px;
                    margin-top: 7px;
                    display: flex;

                    .heading {
                        color: #5EC7D3; 
                    }
                }
            }
        }
    }
}
@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;