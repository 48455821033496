.vaccine-status {
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
  color: #fff;

  &.current {
    background: #24b999;
  }

  &.past {
    background: #d92a1f;
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;