@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
body {
  font-family: Inter;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

// Utility-classes
.grid {
  display: grid;
}
.grid-col-2 {
  grid-template-columns: auto auto;
}
.col-gap-2 {
  column-gap: 2rem;
}
.gap-4 {
  gap: 24px;
}
.gap-2 {
  gap: 12px;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.ant-layout {
  @bgColor: #f9f9f9;
  background-color: @bgColor;
  &-footer {
    background-color: @bgColor;
  }
}

.ant-descriptions-item-label {
  font-weight: 600;
}

// .ant-form-item {
//   &-label {
//     > label {
//       font-size: 17px;
//       color: #7d7d7d;
//     }
//   }
// }

.primary-tag {
  padding: 1px 9px;
  background-color: #70c2ac;
  color: #fff;
  border-radius: 12px;
  display: inline-block;
}

.primary-tag-outline {
  padding: 10px 16px;
  display: block;
  background-color: #eefff9;
  font-weight: 600;
  font-size: 1rem;
}

.custom-card {
  border-radius: 20px;
}

.custom-button {
  height: 55px;
}

.custom-input {
  .ant-select-selector,
  .ant-input-number-lg {
    min-height: 55px;
  }
}

.custom-input.ant-input-number-lg {
  min-height: 55px;

  input {
    min-height: 55px;
  }
}

.custom-textarea {
  background-color: #fbfbfb;
  border-radius: 5px;
}

.custom-select {
  @height: 55px;

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
      .ant-select-customize-input
    )
    .ant-select-selection-search-input {
    height: @height;
  }

  &.ant-select-lg {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    height: @height;

    .ant-select-selector {
      height: @height !important;
      padding: 4px 17px;

      input {
        height: @height !important;
      }

      .ant-select-selection-item {
        line-height: @height !important;
      }

      .ant-select-selection-placeholder {
        line-height: @height !important;
      }
    }
  }
}

.custom-form-item-wrapper {
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 20px;
  border: 1px dashed #d8d8d8;
  margin-bottom: 25px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .list-container {
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;

    .primary-tag-outline {
      margin: 10px;
    }

    .recommendation-tags-container {
      padding: 20px;
    }
  }

  .ant-table-thead {
    th {
      font-weight: 600;
    }
  }
}

.custom-modal {
  top: 30px;

  .ant-modal {
    &-content {
      border-radius: 9px;
      overflow: hidden;
    }

    &-header {
      border: 0;
      background-color: #f9feff;
      padding: 1.5rem 2.3rem;
    }

    &-title {
      font-size: 1.1rem;
      font-weight: 600;
    }

    &-footer {
      border: 0;
      padding: 3rem 4rem;
    }
  }
}

.ant-table {
  tr.ant-table-expanded-row > td {
    // box-shadow: 0px 1px 13px -3px rgba(3, 3, 3, 0.62) inset;
    // -webkit-box-shadow: 0px 1px 13px -3px rgba(3, 3, 3, 0.62) inset;
    // -moz-box-shadow: 0px 1px 13px -3px rgba(3, 3, 3, 0.62) inset;
    background: #f5fffc;
  }

  &-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    content: unset;
  }

  .expanded-table-content-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin: 0;

    > div {
      flex: 1;

      h5 {
        color: #5ec7d3;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.ant-input,
.ant-picker,
.ant-input-number-affix-wrapper {
  border-radius: 4px;
  resize: none;
  border: 1px solid #adadad;

  &-group {
    &-addon {
      border: 1px solid #adadad;
      background: #f5feff;
      border-right: none;
    }

    .ant-input {
      border-left: 0px;
    }
  }
}

.ant-select {
  &-selector {
    border-radius: 4px !important;
    overflow: hidden;
    border: 1px solid #adadad !important;
    .ant-select-selection-placeholder {
      color: #757575;
    }
  }
}

.form-section-title {
  background: #f0f0f0;
  padding: 4px 10px;
  border-radius: 3px;
  margin-bottom: 7px;
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;