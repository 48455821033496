#objective-form-wrapper {
  .custom-small-button {
    border: 1px solid #70c2ac;
    color: #70c2ac;
    border-radius: 20px;
    background-color: #fff;
    margin-bottom: 29px;
    margin-left: 14px;
    padding: 2px 11px;
  }

  .medical-history-list {
    padding: 0;
    list-style: none;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-height: 173px;
    overflow-y: scroll;

    li {
      padding: 10px 20px;
      border-bottom: 1px solid #ddd;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .ant-collapse-borderless {
    background: unset;

    > .ant-collapse-item {
      border: none;

      > .ant-collapse-header {
        background-color: #f6f6f6;
        margin-bottom: 25px;
        padding: 17px 16px;

        .ant-collapse-header-text {
          text-transform: uppercase;
        }
      }

      > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
        top: -13px;
        position: relative;
      }
    }
  }
}

@primary-color: #1DA4B4;@error-color: #C01E4F;@border-radius-base: 6px;@font-family: Inter, sans-serif;